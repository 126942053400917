import React from "react"
import type { GatsbyBrowser, GatsbySSR } from "gatsby"
import { Link } from "gatsby"
import type { DefaultTheme } from "styled-components"
import Hel from "@onestore/hel/dist/Hel"
import type Theme from "@onestore/hel/dist/typings/theme"

export const wrapPageElementWithTheme = (
  brand: Theme.Brand,
  theme: DefaultTheme
): GatsbySSR["wrapPageElement"] | GatsbyBrowser["wrapPageElement"] => {
  return ({ element }) => (
    <Hel
      brand={brand}
      env={process.env.NODE_ENV as "development" | "production" | undefined}
      theme={theme}
      linkComponent={Link}
    >
      {element}
    </Hel>
  )
}

export const onRenderBody: GatsbySSR["onRenderBody"] = ({
  setHeadComponents,
}) => {
  setHeadComponents([
    <link
      key="overpass-font"
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Overpass:200,400,700,800&display=swap"
      crossOrigin="anonymous"
    />,
  ])
}
